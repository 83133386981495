/* Bootstrap v4.5.0 */
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all";
@import "~react-date-range/dist/styles";
@import "~react-date-range/dist/theme/default";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~flag-icons/css/flag-icons.min.css";
@import "./styles/app.scss";
