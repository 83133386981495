#login-header {
    display: flex;
    align-items: center;
}

#login-header img {
    width: 16px;
    height: 16px;
    margin-right: 0.5em;
}
