@import "sb-admin/sb-admin.scss";


// Generate some utility color classes based on theme's bootstrap colors
@mixin color-modifiers($attribute: 'color') {
    $colors: (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800
    ) !default;
    @each $name, $color in $colors {
        &-#{$name} {
            #{$attribute}: $color;
        }
    }
}

.bg {
    @include color-modifiers($attribute: 'background-color');
}
.text {
    @include color-modifiers($attribute: 'color');
}
.border {
    @include color-modifiers($attribute: 'border-color');
}

/* Fix fullcalendar view height on mobile devices */
.fc-scroller {
    height: auto !important;
}
