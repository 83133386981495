@import "./../../../styles/app.scss";

.media-calls-table {

    & thead {
        background-color: $gray-100;
    }

    & .media-header {
        font-weight: bold;
        background-color: rgba($teal, 0.25);
    }

    & .media-totals {
        font-weight: bold;
        background-color: rgba($cyan, 0.25);
    }

    & .spacer {
        line-height: 2em;
    }

}
