@import "./../styles/sb-admin/sb-admin.scss";

.btn-link-plain > button {
    text-decoration: none;
    color: $dark;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;
}

.btn-link-plain > button:hover {
    text-decoration: none;
    color: $dark;
}

