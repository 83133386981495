.loading-overlay {
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-color: rgba(0, 0, 0, 0.1);
    background-image: url("/loading.gif");
    position: fixed;
    z-index: 9009;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}